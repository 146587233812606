export enum UserStatus {
  ENABLE = 'enable',
  DISABLE = 'disable'
}

export interface User {
  id: number
  avatar: string
  username: string
  first_name: string
  last_name: string
  email: string
  email_verified_at: string
  birthdate: string
  status: UserStatus
  created_at: string
}

export type PartialUser = Partial<User>;
